import * as React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Component} from "react";
import {Helmet} from "react-helmet";

export class ScheduleDemo extends Component {

    componentDidMount() {
        this.initializeHubspotForm();
    }

    initializeHubspotForm() {
        if ('hbspt' in window) {
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "25215065",
                formId: "a8fecd78-9bbc-4ad6-aaad-3728dd81c8cf",
                target: "#formContainer"
            });
        } else {
            setTimeout(this.initializeHubspotForm, 500);
        }
    }


    render() {
        return (
            <Layout>
                <section className={"form"}>
                    <aside>
                        <h1>Contact Us</h1>
                        <p className={""}>You can contact us through facebook, twitter, instagram, linkedin, slack
                            or you can send your queries to our email-id hello@thehyperstack.com
                        </p>
                    </aside>
                    <aside className={"form__body"}>
                        <div id="formContainer">
                            <p>Form not showing?
                            <span className={"text-blue pointer"} onClick={()=>this.initializeHubspotForm()}>Reload form</span>
                            </p>
                        </div>
                    </aside>
                </section>

            </Layout>
        )
    }
}

export const Head = () => <Seo
    title="Schedule Demo with Hyperstack"
    description="Contact Us and get to know about the future of digital credentials and badges. Powered by Blockchain Technology, Unhackable, Irreversable, Immutable. So, Its Authentic"
/>

export default ScheduleDemo
